<template>
  <div class="mb-3 card" :style="this.$vuetify.theme.dark ? 'background-color: #1E1E1E;' : ''">
    <v-container fluid>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-subtitle>
            <v-row class="mt-4">
              <v-col cols="4">
                <v-text-field v-model="name" :label="$t('erp.lang_offerName')" :rules="[rules.required]">
                  >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-switch color="primary darken-3" v-model="switch1"
                  :label="$t('erp.lang_happyHourSetPrice') + ' ' + $store.getters['settings/currencyData']('symbol')"
                  @click="switch1 ? switch2 = false : switch2 = true;"></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch color="primary darken-3" v-model="switch2" :label="$t('generic.lang_discountInPercent')"
                  @click="switch2 ? switch1 = false : switch1 = true;"></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <BaseDateTimeInput v-model="from" :label="$t('generic.lang_from')"
                  prepend-icon="mdi-clock-time-four-outline" readonly></BaseDateTimeInput>
              </v-col>
              <v-col cols="6">
                <BaseDateTimeInput v-model="to" :label="$t('generic.lang_till')" prepend-icon="mdi-clock-time-four-outline"
                  readonly></BaseDateTimeInput>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field :label="$t('erp.lang_happyHourSetPrice')" v-model="discount"
                  :prefix="switch1 ? $store.getters['settings/currencyData']('symbol') : `%`"></v-text-field>
              </v-col>
              <v-col v-if="switch2" cols="6">
                <div>
                  <h6>{{ $t('erp.lang_happyHourItemgroup') }}: </h6>
                  <v-select v-model="wareGrp" :items="itemGroupHolder" item-text="name" item-value="id" multiple
                    dense></v-select>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <div>
                  <h6>{{ $t('erp.lang_happyHourItems') }}: </h6>
                  <v-select :items="itemGroupHolder" item-text="name" item-value="id" dense v-model="wareGrpId"
                    @input="getItems(wareGrpId)"></v-select>
                  <v-select v-model="itemsSelected" :items="items" item-text="name" item-value="id" dense multiple
                    :label="$t('erp.lang_items')"></v-select>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6"></v-col>
              <v-col cols="6" align="right">
                <div>
                  <v-btn text color="error" @click="back">
                    {{ $t('generic.lang_cancel') }}
                  </v-btn>
                  <v-btn depressed color="green" :dark="!(!name || name.length < 1)" :disabled="!name || name.length < 1"
                    @click="create">
                    {{ $t('generic.lang_create') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>

      </v-list-item>
    </v-container>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
        :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout"
        :cancel="hideTouchKeyboard" :accept="hideTouchKeyboard" :input="touchKeyboard.input" />
    </div>
  </div>
</template>

<style>
.v-btn--floating {
  width: 30px !important;
  height: 30px !important;
}

.dataTables_filter {
  display: none;
}

.extra-padding .v-input {
  margin-top: 10px;
}

.extra-padding .v-input input {
  padding-bottom: 12px;
  max-height: 42px;
}

.narrow-list .v-input {
  margin-top: 5px !important;
}

.narrow-list .v-input__slot {
  margin-bottom: 0px !important;
}

.narrow-list label {
  margin-bottom: 0px !important;
}
</style>

<script>
import { ENDPOINTS } from '@/config'
import mixin from "../../../../mixins/KeyboardMixIns";
import { mapState } from "vuex";
import { Events } from "@/plugins/events";
import items from "@/store/modules/items";
import BaseDateTimeInput from "@/components/common/BaseDateTimeInput.vue";
import validation from '../../../../mixins/validation/validation';

export default {
  name: "CreateSpecialOfferComponent",
  components: {
    BaseDateTimeInput
  },
  computed: {
    ...mapState([
      'api'
    ]),
    filteredCashierIDs() {
      return this.cashierIDs.map((cashierID) => {
        return {
          value: cashierID[0].toString(),
          text: "Kasse " + cashierID
        };
      });
    }
  },
  mixins: [mixin, validation],
  data() {
    return {
      ENDPOINTS,
      name: "",
      days: [],
      cashierID: [],
      cashierIDs: [],
      from: null,
      to: null,
      menu2: false,
      menu: false,
      modal: false,
      switch1: true,
      switch2: false,
      active: false,
      discount: '',
      wareGrp: [],
      wareGrpId: null,
      items: [],
      itemsSelected: [],
      itemGroupHolder: [],
    }
  },


  watch: {
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    }
  },

  methods: {
    getCashiers() {
      this.loading = true;

      this.axios.post(ENDPOINTS.GENERIC.CASHIERIDS.GET).then((res) => {
        this.cashierIDs = res.data.cashierIDs;
      }).finally(() => this.loading = false);
    },
    back() {
      this.$router.go(-1)
    },
    switchActive(switch1, switch2) {
      switch1 = !switch2;
    },
    create() {
      this.loading = true;
      this.axios.post(ENDPOINTS.ERP.SPECIALOFFER.CREATE, {
        name: this.name,
        from: this.from,
        to: this.to,
        discountType: this.switch1 ? "price" : "percent",
        discountValue: this.discount,
        wareGroupsList : this.wareGrp,
        itemGroupId: this.wareGrpId,
        ItemsList : this.itemsSelected,

      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_happyHourAdded'),
            color: "success"
          });
          this.back()
        }
        else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
      }).finally(() => {
        this.loading = false;
      });
    },
    async searchPayload() {
      let data = await this.$store.dispatch("itemgroups/getItemgroups");

      //ITEMGROUPS
      data = data.map((itemgroup) => {
        return {
          id: itemgroup.id,
          name: itemgroup.name
        }
      }).sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      this.itemGroupHolder = data;
    },
    getItems(id) {
      this.loading = true;
      this.axios.post('/get/erp/itemPerItemgroup/', {
        itemgroupId: id,
      }).then((res) => {
        if (res.data.success) {
          this.items = res.data.items
        }
        else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },


  },
  mounted() {
    this.cashierID.push(this.api.auth.cashierID);
    this.getCashiers();
    this.searchPayload();
  },
}
</script>
