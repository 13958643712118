<template>
  <div>
    <page-title :heading="$t('erp.lang_specialOffers')" show-previous-button :subheading="$t('erp.lang_addSpecialOffer')" :icon=icon></page-title>
    <div class="app-main__inner">
      <CreateSpecialOfferComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import CreateSpecialOfferComponent from "@/components/erp/baseData/specialoffers/CreateSpecialOfferComponent.vue";

export default {
  components: {
    PageTitle,
    CreateSpecialOfferComponent
  },

  data: () => ({
    icon: 'pe-7s-plugin icon-gradient bg-tempting-azure',
  })
}
</script>